<template>
  <section id="cities">
    <card icon="map-marker-alt"
          :headline="$t('visitors.topCities.headline')"
          :description="$t('visitors.topCities.description')"
    >

    </card>
  </section>
</template>

<script>
  export default {
    name: "Cities"
  }
</script>

<style scoped lang="scss">

</style>
